import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import UserMenu from "../UserMenu";

const GlobalNavbar = () => {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand as={Link} to="/app">FACT</Navbar.Brand>
                <Navbar.Toggle />
                <Nav className="me-auto" >
                    <Nav.Link as={NavLink} to="/app">Home</Nav.Link>
                </Nav>
                <UserMenu />
            </Container>
        </Navbar>
    )
}

export default GlobalNavbar;
