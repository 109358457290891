import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppwriteProvider } from './hooks/useAppwrite';

import App from './App';
import { Login, Error404, LandingPage } from './pages';

import './custom.scss';
import ProtectedRoute from './components/ProtectedRoute';


ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <AppwriteProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/app" element={<ProtectedRoute><App /></ProtectedRoute>} >
              <Route path="*" element={<Error404 />} />
              <Route index element={<LandingPage />} />
            </Route>
          </Routes>
        </AppwriteProvider>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
