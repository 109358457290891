import React from "react";
import { Card, Form,  Button, Alert } from "react-bootstrap";
import { useAppwrite } from "../hooks/useAppwrite";

type LoginState = {
    email: string;
    password: string;
};

/**
 * Display Login Form
 * @returns {JSX.Element}
 */
function LoginForm(): JSX.Element {

    const appwriteCtx = useAppwrite();

    const [state, setState] = React.useState<LoginState>({} as LoginState);

    /**
     * handleChange depends on the form field name to be the same as the state property
     * @param event
     */
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        appwriteCtx.login(state.email, state.password);
    }



    return (
        <Card className="mx-auto mx-1 my-auto col-11 col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <Card.Header>Login</Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="loginFormEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={handleChange} value={state.email} name="email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="loginFormPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={state.password} onChange={handleChange} name="password" />
                    </Form.Group>
                    {appwriteCtx.error && <Alert variant="danger" className="d-none" id="loginFormError"> There was an error logging in. Please try again. </Alert>}

                    <div className="float-end">
                        <Button variant="primary" type="submit">Login</Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>);
}


export default LoginForm;