import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
    return (
        <footer className="footer bg-dark pt-3">
            <Container className="text-center text-light">
                <p> &copy; {new Date().getFullYear()} ikf Institut GmbH</p>
            </Container>
        </footer>
    );
}

export default Footer;
