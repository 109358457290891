import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { Footer, GlobalNavbar } from "./components/layout";

const App = () => {
  return (
    <div>
      <GlobalNavbar />
      <Container className="mt-3">
        <Outlet />
      </Container>
      <Footer />
    </div >
  );
};
export default App;