
import React from "react"
import { Navigate, useLocation } from "react-router-dom";
import { useAppwrite } from "../hooks/useAppwrite";

interface Props {
    children: JSX.Element;
}

/**
 * A wrapper around the element which checks if the user is authenticated
 * If authenticated, renders the passed element
 * If not authenticated, redirects the user to Login page.
 */
const ProtectedRoute = ({ children }: Props): JSX.Element => {
    let location = useLocation()
    let appwriteCtx = useAppwrite();
    return appwriteCtx.isAuthenticated ? (
        children
    ) : (
        <Navigate to="/" state={{ from: location }} />
    )
}

export default ProtectedRoute