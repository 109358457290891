import { Appwrite, AppwriteException, Models } from "appwrite";

import { useEffect, useState, createContext, useContext, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom"

export interface IAppwriteContext {
    appwrite?: Appwrite;
    userSession?: Models.Session;
    user?: Models.User<Models.Preferences>;
    loading: boolean;
    isAuthenticated: boolean;
    error?: AppwriteException;
    login: (email: string, password: string) => void;
    logout: () => void;
}
interface Props {
    children: ReactNode;
}


const AppwriteContext = createContext<IAppwriteContext>({} as IAppwriteContext);

/**
 * Appwrite Authentication provider
 * @param children ReactNode
 * @returns
 */
function AppwriteProvider({ children }: Props): JSX.Element {
    const [appwrite, setAppwrite] = useState<Appwrite>();
    const [userSession, setUserSession] = useState<Models.Session>();
    const [user, setUser] = useState<Models.User<Models.Preferences>>();
    const [error, setError] = useState<AppwriteException>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const APPWRITE_URL = process.env.REACT_APP_APPWRITE_URL;
    const APPWRITE_PROJECT = process.env.REACT_APP_APPWRITE_PROJECT;

    //Check env vars for Appwrite URL and Project
    if (!APPWRITE_URL || !APPWRITE_PROJECT) {
        throw new Error("APPWRITE_URL and APPWRITE_PROJECT must be set in .env file");
    }
    if (!appwrite) {
        const tmpAppwrite = new Appwrite()
            .setEndpoint(APPWRITE_URL)
            .setProject(APPWRITE_PROJECT);
        setAppwrite(tmpAppwrite);
    }
    const location = useLocation();
    const navigate = useNavigate();

    //Reset Errors if path changes
    useEffect(() => {
        setError(undefined);
    }, [location.pathname]);

    // Load user object from backend
    useEffect(() => {
        if (userSession) {
            setLoading(true);
            appwrite?.account.get()
                .then((user: Models.User<Models.Preferences>) => {
                    setUser(user);
                })
                .catch((error: AppwriteException) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [userSession, appwrite]);

    useEffect(() => {
        if (!userSession) {
            setLoading(true);
            appwrite?.account.getSession("current")
                .then((session: Models.Session) => {
                    // eslint-disable-next-line 
                    storeSessionAndNavigate(session);
                })
                .catch((error: AppwriteException) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [appwrite, userSession]);

    function storeSessionAndNavigate(session: Models.Session) {
        setUserSession(session);
        setIsAuthenticated(true);
        navigate("/app");
    }

    function login(email: string, password: string): void {
        setLoading(true);
        appwrite?.account.createSession(email, password)
            .then((result: Models.Session) => {
                storeSessionAndNavigate(result);
            })
            .catch((error: AppwriteException) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function logout(): void {
        if (userSession) {
            setLoading(true);
            appwrite?.account.deleteSession(userSession.$id)
                .then(() => {
                    setIsAuthenticated(false);
                    setUserSession(undefined);
                    navigate("/");
                })
                .catch((error: AppwriteException) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <AppwriteContext.Provider value={{ appwrite, userSession, isAuthenticated, user, loading, error, login, logout }}>
            {children}
        </AppwriteContext.Provider>
    );
}

/**
 * Hook to get appwrite auth context
 * @returns {IAppwriteContext}
 */
function useAppwrite(): IAppwriteContext {
    const context = useContext(AppwriteContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export { AppwriteProvider, useAppwrite };
